<template>
  <div
    :class="
      actionSelected
        ? 'vacancy-listing-right-action-bar-container'
        : 'plannerSelected'
    "
  >
    <div class="search-container" v-if="actionSelected">
      <a-input-search />
    </div>

    <div class="toggle-container">
      <div
        style="
          height: 44px;
          border: 1px solid rgba(255, 255, 255, 0.5)
          box-sizing: border-box;
          border-radius: 15px;
        "
      >
        <span>
          <a-button
            type="link"
            @click="onClickAction"
            :class="actionSelected ? 'selected' : 'unSelected'"
          >
            <span v-if="actionSelected" class="selected-text">Action</span>
            <span v-else></span>
          </a-button>
        </span>
        <span>
          <a-button
            type="link"
            @click="onClickPlanner"
            :class="plannerselected ? 'selected' : 'unSelected'"
          >
            <span v-if="plannerselected" class="selected-text">Planner</span>
            <span v-else></span>
          </a-button>
        </span>
      </div>
    </div>
    <div v-if="actionSelected">
      <div class="menu-container">
        <div class="reports-workflow-text">Workflow</div>
        <a-menu
          mode="inline"
          :style="{ height: '100%', borderRight: 0 }"
          :openKeys="selectedMenuKey"
        >
          <a-sub-menu key="vacancy">
            <template #title>
              <span> Vacancy </span>
            </template>
            <a-menu-item key="create_vacancy" @click="createVacancy"
              >Create Vacancy</a-menu-item
            >
            <a-menu-item key="attach_candidates" @click="attachCandidates"
              >Attach Candidates</a-menu-item
            >
            <a-menu-item key="change_stage" @click="showChangeStageModal">
              Change Stage
            </a-menu-item>
            <a-menu-item key="attach_to_vacancy" @click="attachToVacancy">
              Attach to Vacancy
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="candidate">
            <template #title>
              <span> Candidate </span>
            </template>
            <a-menu-item key="create_candidate" @click="createCandidate"
              >Create Candidate</a-menu-item
            >
          </a-sub-menu>
          <a-sub-menu key="sub3">
            <template #title>
              <span> Clients </span>
            </template>
            <a-menu-item key="create_company" @click="createAccount"
              >Create Company</a-menu-item
            >
            <a-menu-item key="create_client_profile" @click="createClientPerson"
              >Create Client Person</a-menu-item
            >
          </a-sub-menu>
          <a-sub-menu key="sub4">
            <template #title>
              <span> Tasks </span>
            </template>
          </a-sub-menu>

          <a-divider class="reports-side-bar-divider" />
          <div class="action-section">
            <div>
              <p>Data</p>
            </div>
            <a-menu-item key="vacancy_delete" class="action-sub-section-text">
              <DeleteOutlined class="vacancy-right-bar-delete-icon" />Delete
            </a-menu-item>
            <div class="action-sub-section">
              <a-menu-item key="vacancy_merge" class="action-sub-section-text"
                >Merge</a-menu-item
              >
              <a-menu-item key="vacancy_export" class="action-sub-section-text"
                >Export</a-menu-item
              >
              <a-menu-item
                key="vacancy_export_landscape"
                class="action-sub-section-text"
                >Export Landscape Report</a-menu-item
              >
              <a-menu-item key="vacancy_copy" class="action-sub-section-text"
                >Copy</a-menu-item
              >
              <a-menu-item
                key="edit_vacancy_profile"
                class="action-sub-section-text"
                @click="createVacancy"
              >
                Edit Vacancy Profile
              </a-menu-item>
            </div>
          </div>
        </a-menu>
      </div>
    </div>
    <div v-if="plannerselected" class="right-side-bar-planner-wrapper">
      <div class="date-display-wrapper">
        <div class="today-text">Today</div>
        <div class="date-respone-text">{{ displayDate }}</div>
      </div>
      <div>
        <ul style="padding: 17px; margin: 0px">
          <li>Meeting with Client Person at 10 am</li>
          <li>Meeting with Client Person at 11 am</li>
          <li>Task to be completed</li>
        </ul>
      </div>

      <div class="calendar-wrapper">
        <a-calendar
          v-model:value="calenderValue"
          :fullscreen="false"
          @panelChange="onPanelChange"
          @select="onSelect"
        >
          <template #headerRender="{ value, onChange }">
            <div style="padding: 10px">
              <a-row type="flex" justify="space-between">
                <a-col>
                  <div>
                    <a-popover
                      v-model:visible="visible"
                      trigger="click"
                      placement="bottom"
                    >
                      <template #content>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Meeting
                        </div>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Task
                        </div>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Reminder
                        </div>
                      </template>
                      <div class="open-popover-plus-icon"><PlusOutlined /></div>
                    </a-popover>
                  </div>
                </a-col>
                <a-col>
                  <a-select
                    size="small"
                    :dropdown-match-select-width="false"
                    class="my-year-select"
                    :value="String(value.year())"
                    @change="
                      (newYear) => {
                        onChange(value.clone().year(newYear));
                      }
                    "
                  >
                    <a-select-option
                      v-for="val in getYears(value)"
                      :key="String(val)"
                      class="year-item"
                    >
                      {{ val }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col>
                  <a-select
                    size="small"
                    :dropdown-match-select-width="false"
                    :value="String(value.month())"
                    @change="
                      (selectedMonth) => {
                        onChange(
                          value.clone().month(parseInt(selectedMonth, 10))
                        );
                      }
                    "
                  >
                    <a-select-option
                      v-for="(val, index) in getMonths(value)"
                      :key="String(index)"
                      class="month-item"
                    >
                      {{ val }}
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </template>
        </a-calendar>
      </div>

      <div>
        <div
          style="
            display: flex;
            border-bottom: 1px solid #b3b1b4;
            cursor: pointer;
          "
          @click="OnclickArrow"
        >
          <div class="calendar-text">Calendar</div>
          <div style="margin-left: auto" v-if="hideCalendarOptions">
            <DownOutlined />
          </div>
          <div style="margin-left: auto" v-else>
            <UpOutlined />
          </div>
        </div>
      </div>
      <div style="display: grid; margin-top: 10px" v-if="hideCalendarOptions">
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Meetings</a-checkbox
        >
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Tasks</a-checkbox
        >
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Reminders</a-checkbox
        >
      </div>
    </div>
    <a-modal
      class="filter-modal"
      title=""
      width="500px"
      v-model:visible="changeStageModalVisibility"
      :closable="false"
      centered
      footer=""
      destroyOnClose
    >
      <ChangeStage @closeChangeStage="closeChangeStage" />
    </a-modal>
  </div>
</template>
<script>
import { useStore } from "vuex";
import ChangeStage from "./changeCandidateStage.vue";
import {
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { ref, onMounted, computed } from "vue";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import Utils from "../../../utils";
let actionSelected = ref(true);
let plannerselected = ref(false);
let hideCalendarOptions = ref(true);
let changeStageModalVisibility = ref(false);
export default {
  components: {
    DeleteOutlined,
    DownOutlined,
    UpOutlined,
    PlusOutlined,
    ChangeStage,
  },
  setup(props, context) {
    const store = useStore();
    let displayDate = ref("");
    const selectedMenuKey = ref(["vacancy"]);
    const router = useRouter();
    let calenderValue = ref();
    const visible = ref(false);
    const hide = () => {
      visible.value = false;
    };
    const createVacancy = () => {
      router.push(`/vacancy/create-new-vacancy`);
    };
    const attachCandidates = () => {
      const id = computed(
        () => store.getters["workbenchStore/getVacancyId"]
      ).value;
      router.push(`/vacancy/${id}/attachCandidates`);
    };
    const onClickAction = () => {
      plannerselected.value = false;
      actionSelected.value = true;
    };

    const onClickPlanner = () => {
      plannerselected.value = true;
      actionSelected.value = false;
    };
    const OnclickArrow = () => {
      hideCalendarOptions.value = !hideCalendarOptions.value;
    };
    const getYears = (value) => {
      const year = value.year();
      const years = [];

      for (let i = year - 10; i < year + 10; i += 1) {
        years.push(i);
      }

      return years;
    };

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    const onSelect = (value) => {
      displayDate.value = Utils.dispalyDateMonthYear(value);
    };

    const getMonths = (value) => {
      const current = value.clone();
      const localeData = value.localeData();
      const months = [];

      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }

      return months;
    };

    const setDisplayDate = () => {
      displayDate.value = Utils.dispalyDateMonthYear(new Date());
    };

    const showChangeStageModal = () => {
      if (
        computed(() => store.getters["workbenchStore/getSelectedCandidates"])
          .value.length
      ) {
        changeStageModalVisibility.value = true;
      } else {
        changeStageModalVisibility.value = false;
        message.error("Please Seelect atleast one Candidate from the Listing");
      }
    };

    const attachToVacancy = () => {
      store.dispatch("workbenchStore/attachToVacancy");
      console.log("first");
    };

    function closeChangeStage(status, responseStatus) {
      changeStageModalVisibility.value = status;
      context.emit("updateAttachedCandidateListing", responseStatus);
    }

    onMounted(() => {
      setDisplayDate();
    });

    return {
      changeStageModalVisibility,
      showChangeStageModal,
      attachToVacancy,
      closeChangeStage,
      selectedMenuKey,
      createVacancy,
      attachCandidates,
      onClickAction,
      actionSelected,
      plannerselected,
      onClickPlanner,
      calenderValue,
      onPanelChange,
      onSelect,
      OnclickArrow,
      hideCalendarOptions,
      checked: ref(false),
      getYears,
      getMonths,
      visible,
      hide,
      displayDate,
      setDisplayDate,
    };
  },
};
</script>
<style></style>
<style lang="scss">
@import "../styles/rightSideBar.scss";
</style>
