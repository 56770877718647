<template>
  <div>
    <div class="popover-container">
      <div class="popover-title">Candidate Process Status</div>
      <div class="popover-main-section">
        <div class="long-list-section">
          <div v-for="(status, index) in statusData" :key="index">
            <div class="title-count-section">
              <div class="stage-title">{{ status.name }}</div>
              <div class="stage-count">
                <span>{{ status.count }}</span>
                <span class="longlist-color dot"> </span>
              </div>
            </div>
            <div class="tags-section">
              <a-tag
                v-for="(candidate, index) in status.candidates"
                :key="index"
                color="#f4db8b"
                class="filter-tags"
              >
                {{ candidate.name }}
              </a-tag>
            </div>
            <div class="add-to-section">
              <a-button type="link" @click="addToSection(status)">
                <PlusOutlined />
                Add to Section
              </a-button>
            </div>
            <a-divider />
          </div>
        </div>
        <a-divider />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed, onMounted, ref } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import Services from "../../../services/apis";

export default {
  components: { PlusOutlined },
  setup(props, context) {
    const store = useStore();
    let statusData = reactive([]);
    let fetchedData;
    onMounted(() => {
      fetchStatusData();
    });

    const fetchStatusData = () => {
      const vacancyId = computed(
        () => store.getters["workbenchStore/getVacancyId"]
      ).value;
      Services.getCandidatesProcedureStatusforVacancy(vacancyId).then(
        (response) => {
          const { data } = response;
          if (data) {
            Object.assign(statusData, data);
          }
        }
      );
    };

    function addToSection(statusObject) {
      console.log("statusObject", statusObject);
      const selectedCandidates = computed(
        () => store.getters["workbenchStore/getSelectedCandidates"]
      ).value;
      // store.dispatch("workbenchStore/validateSelectedCandidates", statusObject);

      const statusCandidatesIds = statusObject.candidates
        ? statusObject.candidates.map((candidate) => candidate.id)
        : [];
      const rejectedCandidates = [];
      const validCandidates = selectedCandidates.filter((candidate) => {
        if (!statusCandidatesIds.includes(candidate.id)) {
          return candidate;
        }
        rejectedCandidates.push(candidate);
      });

      if (validCandidates.length) {
        const vacancyId = computed(
          () => store.getters["workbenchStore/getVacancyId"]
        ).value;
        const stageId = statusObject.id;
        const payload = {
          id: vacancyId,
          stageId: stageId,
          data: validCandidates.map((candidate) => candidate.id),
        };
        Services.UpdateCandidatesProcedureStatusforVacancy(payload).then(
          (response) => {
            if (response.data.success) {
              console.log("response.data.success", response.data.success);
              context.emit("closeChangeStage", false, response.data.success);
              message.success(
                "Successfully Added " +
                  validCandidates.map((candidate) => candidate.name) +
                  " to " +
                  statusObject.name +
                  " stage."
              );
            }
          }
        );
      }
      if (validCandidates.length === 0 && rejectedCandidates.length) {
        message.error(
          rejectedCandidates.map((candidate) => candidate.name) +
            " have not been added to " +
            statusObject.name +
            " stage."
        );
        context.emit("closeChangeStage", false);
      }
    }
    return {
      searchValue: ref(""),
      addToSection,
      statusData,
      fetchedData,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/changeCandidateStage.scss";
</style>
